import { Currency } from '@pancakeswap/sdk'
import { useV2Pair } from 'hooks/usePairs'
import styled from 'styled-components'
import { GraphQLClient } from 'graphql-request'
import { useState } from 'react'
import { TVChartContainer as TVChartContainerAdvanced } from '../tvchart'

type PriceChartContainerProps = {
  inputCurrencyId?: string
  inputCurrency?: Currency
  outputCurrencyId?: string
  outputCurrency?: Currency
  isChartExpanded?: boolean
  setIsChartExpanded?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  currentSwapPrice?: {
    [key: string]: number
  }
  isMobile?: boolean
  isFullWidthContainer?: boolean
}

const ShadowContainerStyle = styled.div`
  z-index: 1;
  border: 1px solid #403d3f;
  margin-bottom: 15px;
  background: linear-gradient(90deg, #180110 0%, #160110 50%, #140110 100%);
  box-shadow: #fff 0px 2px 20px;
  border-radius: 16px;
`

const PriceChartContainer: React.FC<React.PropsWithChildren<PriceChartContainerProps>> = ({
  inputCurrency,
  outputCurrency,
  isChartExpanded,
  // setIsChartExpanded,
  isChartDisplayed,
  isMobile,
  isFullWidthContainer = false,
  currentSwapPrice,
}) => {

  const graphQLClientV2 = new GraphQLClient("https://node.9inch.io/subgraphs/name/exchange-pulsechain")

  let pairAddressV2 = ""
  let usedPair = ""

  const [selectedPair, setSelectedPair] = useState<string>("")

  const [pairState, pair] = useV2Pair(inputCurrency ?? undefined, outputCurrency ?? undefined)

  if (!pair || !pairState) {
    return null
  }

  if (pairState === 2 && pair) {
    pairAddressV2 = pair?.liquidityToken?.address.toLowerCase()
    // usedPair = pairAddressV2
  }

  const graphQLClientV3 = new GraphQLClient("https://node.9inch.io/subgraphs/name/nineinch/exchange-v3-mainnet")
  const token0Address = inputCurrency?.wrapped?.address.toLowerCase()
  const token1Address = outputCurrency?.wrapped?.address.toLowerCase()

  if (!token0Address || !token1Address) {
    return null
  }

  if (!isChartDisplayed) {
    return null
  }

  const queryV2 = `	query {
		pairs( where: { 
      id_in: ["${pairAddressV2}"]
    	}, orderBy: volumeUSD, orderDirection: desc) {
			id
      volumeUSD,
		}
	}`

  const queryV3 = `query Pools {
  pools(
    where: {
      liquidity_gt: 0,
      token0_in: [
        "${token0Address}", 
        "${token1Address}"
      ],
      token1_in: [
        "${token0Address}", 
        "${token1Address}"
      ]
    }
    orderDirection: desc
    orderBy: volumeUSD
  ) {
    id
    volumeUSD
  }
}`
  // console.log("token0Address", token0Address)
  // console.log("token1Address", token1Address)


  graphQLClientV3.request(queryV3).then((data) => {
    graphQLClientV2.request(queryV2).then((dataV2) => {
      // console.log(data)
      // console.log(dataV2)

      // compare volumeUSD from V2 and V3 and select the pair with the highest volume
      const volumeUSDV2 = Number(dataV2.pairs[0]?.volumeUSD) ?? 0
      const volumeUSDV3 = Number(data.pools[0]?.volumeUSD) ?? 0
      // console.log("volumeUSDV2", volumeUSDV2)
      // console.log("volumeUSDV3", volumeUSDV3)

      usedPair = volumeUSDV2 > volumeUSDV3 ? pairAddressV2 : data?.pools[0]?.id
      // console.log("usedPair", usedPair)

      // force use of V2 pair for PLS/BBC
      if (usedPair === "0x80682d8d8ba6450b5585e9d5dfaf04fb91c04ba6") {
        usedPair = pairAddressV2;
      }

      setSelectedPair(usedPair)
    })
  })

  return (
    <ShadowContainerStyle>
      {selectedPair && (<TVChartContainerAdvanced pair={selectedPair} />)}
    </ShadowContainerStyle>
  )
}

export default PriceChartContainer
